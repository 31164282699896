import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import { Tags } from '@tryghost/helpers-gatsby';
// import { readingTime as readingTimeHelper } from '@tryghost/helpers';

const PoemCard = ({ post }) => {
	let url = `${post.primary_tag.slug}/${post.slug}/`;
	let type = {
		type: `quote`,
		label: `Q: `,
	};
	if (post.primary_tag.slug !== `quotes`) {
		type.type = `poem`;
		type.label = `P: `;
		url = `/poems/${post.primary_tag.slug}/${post.slug}/`;
	}
	
	// const readingTime = readingTimeHelper(post);
	const excerptLength = post.excerpt.split(`\n`).length;
	const previewLength = 2;
	const excerpt = post.excerpt.split(`\n`).slice(0, previewLength); //.slice(0,6).join(`\n`);
	return (
		<div className="post-card-item">
			<span>{post.primary_tag.meta_title}</span>
			<Link to={url} className="post-card">
				<header className="post-card-header">

					{post.feature_image &&
                    <div className="post-card-image" style={{ backgroundImage: `url(${post.feature_image})` }}></div>}
					{post.featured && <span>Featured</span>}

					<h2 className="post-card-title">{post.title}</h2>
				</header>
				<section className="post-card-excerpt">{excerpt.map((line, i) => (<Fragment key={i}>
					{line} <br />
				</Fragment>)
				)}
				{(excerptLength < previewLength) ? `` : <i>...continued</i>}
				</section>
			</Link>
			{/*			<footer className="post-card-footer">
				<div className="post-card-footer-left">

				</div>
				<div className="post-card-footer-right">
					{post.primary_tag.name}
				</div>
			</footer>
*/}		</div>
	);
};

PoemCard.propTypes = {
	post: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		feature_image: PropTypes.string,
		featured: PropTypes.bool,
		tags: PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string,
			})
		),
		excerpt: PropTypes.string.isRequired,
		primary_author: PropTypes.shape({
			name: PropTypes.string.isRequired,
			profile_image: PropTypes.string,
		}).isRequired,
		primary_tag: PropTypes.shape({
			slug: PropTypes.string,
			meta_title: PropTypes.string,
		}),
	}).isRequired,
};

export default PoemCard;
